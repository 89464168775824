@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;600&display=swap');

.BambooHR-ATS-board {
  text-align: left;
  font-family: 'Lato', sans-serif;
}

.BambooHR-ATS-board h2 {
  font-size: 24px !important;
  color: #fff !important;
}

.BambooHR-ATS-board ul {
  font-size: 12px;
}

.BambooHR-ATS-board ul > ul:last-of-type {
  margin-bottom: 0px;
}

.BambooHR-ATS-board li {
  color: #686868;
  color: #fff !important;
}

.BambooHR-ATS-board a {
  font-size: 16px !important;
  text-decoration: none !important;
  font-weight: bold;
  line-height: 1.9em;
}

.BambooHR-ATS-board a:link,
.BambooHR-ATS-board a:visited {
  text-decoration: none !important;
  color: #fff !important;
}

.BambooHR-ATS-board a:hover {
  color: gray !important;
}

.BambooHR-ATS-board .BambooHR-ATS-blankState {
  color: #adadad;
  font-size: 15px;
}

.BambooHR-ATS-board .BambooHR-ATS-blankState strong {
  font-size: 16px;
  font-weight: 600;
}

#BambooHR-Footer {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #999;
  text-align: right;
  margin: 9px 9px 0px 0px;
}

#BambooHR-Footer img {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .BambooHR-ATS-Jobs-Item {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }

  .BambooHR-ATS-Jobs-Item a {
    line-height: 1.5em!important;
    flex-grow: 1;
    display: block;
    text-decoration: none;
  }

  .BambooHR-ATS-Jobs-Item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Extralight.5df57571.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Extralight.d8bf9748.woff) format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-ExtralightItalic.cbe375dd.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-ExtralightItalic.46a161fb.woff) format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Light.ff2d91b9.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Light.83c2a0d6.woff) format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-LightItalic.7d05c1e9.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-LightItalic.7f73efec.woff) format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Regular.eb38d80f.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Regular.f134b2c5.woff) format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Italic.1591f333.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Italic.4d10de11.woff) format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Medium.c769b9ad.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Medium.b9031fab.woff) format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-MediumItalic.b37bd3e5.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-MediumItalic.2788023b.woff) format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Semibold.bd2e31d9.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Semibold.585130ff.woff) format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-SemiboldItalic.b935fc7b.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-SemiboldItalic.0161e1fc.woff) format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-Bold.ae798fbc.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-Bold.0e97a8c2.woff) format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(/_next/static/media/GeneralSans-BoldItalic.1dc66636.woff2) format('woff2'),
    url(/_next/static/media/GeneralSans-BoldItalic.a9f06465.woff) format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'SortsMillGoudy';
  src: url(/_next/static/media/SortsMillGoudy-Italic.5c37c4e9.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

