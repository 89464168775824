@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Extralight.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Extralight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-ExtralightItalic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Light.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-LightItalic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Regular.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Italic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Italic.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Medium.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-MediumItalic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Semibold.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-SemiboldItalic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-Bold.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans/GeneralSans-BoldItalic.woff2') format('woff2'),
    url('./GeneralSans/GeneralSans-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'SortsMillGoudy';
  src: url('./SortsMillGoudy/SortsMillGoudy-Italic.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
